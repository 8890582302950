import React from 'react'
import { Button } from '../../components/button/button'
import { DomParser } from './domParser'

export const button: DomParser = (element, { 'data-size': size = 'large', ...props }, utils) => {
  const classes = utils.classes()

  if (size === 'large') {
    props.primary = true
  } else {
    props.secondary = true
  }

  if (classes.includes('btn-btn-black')) {
    // @ts-ignore
    return <Button { ...props } buttonColor="black" />
  }

  if (classes.includes('btn-btn-orange')) {
    // @ts-ignore
    return <Button { ...props } buttonColor="orange" />
  }

  if (classes.includes('btn-btn-green')) {
    // @ts-ignore
    return <Button { ...props } buttonColor="green" />
  }

  return element
}

button.match = (element, props, utils) => element.name === 'a' && utils.classes().some(className => className.match(/^btn-btn-(black|orange|green)$/))